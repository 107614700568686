import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const TermsModal = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // Check if the user has already accepted the terms (read from cookie)
  useEffect(() => {
    const accepted = Cookies.get("termsAccepted");
    if (accepted) {
      setIsAccepted(true); // Hide the modal if the user has already accepted
    }
  }, []);

  const handleAccept = () => {
    if (isChecked) {
      // Set a cookie when the user accepts the terms
      Cookies.set("termsAccepted", true, { expires: 365 }); // Cookie expires in 1 year
      setIsAccepted(true);
    }
  };

  return (
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center text-justify ${
        isAccepted ? "hidden" : "visible"
      }`}
    >
      <div className="bg-white rounded-md w-full max-w-4xl max-h-[500px] overflow-y-auto m-2">
        <h3 className="text-white text-xl font-semibold m-0 bg-blue-500 py-4 px-6 rounded-t-md">
          Credit & Disclaimer
        </h3>
        <div className="text-xs mt-2 p-6">
          <p className="text-gray-600 mb-4">
            IDEAL-Gen.AI is the result of a collaborative initiative under the
            <b>
              {" "}
              Inter-institutional Collaborative Activities for Teaching
              Development and Language Enhancement (IICA-TDLE)
            </b>{" "}
            project, funded by the University Grants Committee (UGC) of Hong
            Kong. Led by Lingnan University, this project involves collaboration
            with The Hong Kong University of Science and Technology, The Chinese
            University of Hong Kong and The Hong Kong Polytechnic University.
          </p>
          <p className=" text-gray-600 mb-4">
            By accessing the IDEAL-Gen.AI dashboard, you acknowledge that you
            have read, understood and agreed to our{" "}
            <a
              href="https://www.ideal-gen.ai/terms-of-use"
              target="_blank"
              className="text-blue-500 underline"
            >
              Terms of Use
            </a>
            . This platform is designed exclusively for educational purposes and
            is intended for use by educators, including teachers, instructors
            and professors.
          </p>
          <p className=" text-gray-600 mb-4">
            As a user, you are responsible for ensuring that your use of the
            platform complies with the guidelines outlined in our{" "}
            <a
              href="https://www.ideal-gen.ai/terms-of-use"
              target="_blank"
              className="text-blue-500 underline"
            >
              Terms of Use
            </a>
            . You agree not to use the platform for any unlawful or unethical
            activities. While IDEAL-Gen.AI aims to generate high-quality content
            and resources, we do not guarantee the accuracy, completeness or
            reliability of the materials generated through the platform. Users
            are encouraged to critically assess and adapt the content to fit
            their specific educational needs.
          </p>
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              className="mr-2 h-4 w-4 border border-gray-300 rounded-sm"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            <span className="text-sm text-gray-600">
              I accept and agree to the{" "}
              <a
                href="https://www.ideal-gen.ai/terms-of-use"
                target="_blank"
                className="text-blue-500 underline"
              >
                Terms of Use
              </a>
            </span>
          </div>
          <div className="flex items-center mb-6">
            <input
              type="checkbox"
              className="mr-2 h-4 w-4 border border-gray-300 rounded-sm"
            />
            <span className="text-sm text-gray-600">Do not show again</span>
          </div>
          <button
            onClick={handleAccept}
            className={`w-full text-white font-semibold py-2 rounded-lg ${
              isChecked ? "bg-blue-500" : "bg-blue-300 cursor-not-allowed"
            }`}
            disabled={!isChecked}
          >
            Accept
          </button>
          <div className="mt-4 text-center">
            <a
              href="https://www.ideal-gen.ai/privacy-policy"
              target="_blank"
              className="text-sm text-gray-500 underline"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://www.ideal-gen.ai/terms-of-use"
              target="_blank"
              className="text-sm text-gray-500 underline"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
