import React from "react";
import { useNavigate } from "react-router-dom";
import { BookA, BookOpen, ClipboardList } from "lucide-react";
import TermsModal from "../ui/TermsModal";

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <div className="space-y-4">
      <TermsModal/>
      <h2>Dashboard</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        <div
          className="bg-white p-4 border border-blue-500 rounded hover:bg-blue-500 hover:text-white cursor-pointer"
          onClick={() => navigate("/learning-activity")}
        >
          <BookA size={40} className="mx-auto mb-2" />
          <h2 className="text-center text-lg font-semibold">
            Generate Learning Activity
          </h2>
        </div>
        <div
          className="bg-white p-4 border border-blue-500 rounded hover:bg-blue-500 hover:text-white cursor-pointer"
          onClick={() => navigate("/lesson-plan")}
        >
          <BookOpen size={40} className="mx-auto mb-2" />
          <h2 className="text-center text-lg font-semibold">
            Generate Lesson Plan
          </h2>
        </div>
        <div
          className="bg-white p-4 border border-blue-500 rounded hover:bg-blue-500 hover:text-white cursor-pointer"
          onClick={() => navigate("/assessment-task")}
        >
          <ClipboardList size={40} className="mx-auto mb-2" />
          <h2 className="text-center text-lg font-semibold">
            Generate Assessment Task
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
