import "./App.css";
import { Routes, Route } from "react-router-dom";

import { useEffect, useState } from "react";
import Dashboard from "./components/Dashboard/Dashboard";
import { LearningActivity } from "./components/Pages/LearningActivity";
import { LessonPlan } from "./components/Pages/LessonPlan";
import { AssessmentTask } from "./components/Pages/AssessmentTask";
import Layout from "./components/Layout/Layout";

function App() {
  return (
 
       <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="learning-activity" element={<LearningActivity />} />
          <Route path="lesson-plan" element={<LessonPlan />} />
          <Route path="assessment-task" element={<AssessmentTask />} />
        </Route>
      </Routes>
  
  );
}

export default App;
