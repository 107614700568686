import React, { useEffect, useState, useRef } from "react";
import { Plus, Minus, RefreshCcw } from "lucide-react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
} from "react-share";
import iconImage from "../../assets/Icon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShareButton = ({ finalResponse }) => {
  const isMobileOrTablet = /Mobi|Android/i.test(window.navigator.userAgent);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleShareClick = () => {
    if (navigator.share && finalResponse) {
      navigator.share({
        title: "Learning Activity",
        text: finalResponse.replace(/<\/?[^>]+(>|$)/g, ""), // Remove HTML tags if needed
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    // Add event listener to detect clicks outside of the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {isMobileOrTablet ? (
        <button
          className={`flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 ${
            finalResponse
              ? "cursor-pointer hover:bg-gray-200"
              : "cursor-not-allowed"
          }`}
          onClick={handleShareClick}
          disabled={!finalResponse}
        >
          <i className="fa-solid fa-share-nodes mr-2"></i>
          Share
        </button>
      ) : (
        <div className="relative" ref={dropdownRef}>
          <button
            className={`flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 ${
              finalResponse
                ? "cursor-pointer hover:bg-gray-200"
                : "cursor-not-allowed"
            }`}
            onClick={()=> setDropdownVisible(!dropdownVisible)}
            disabled={!finalResponse}
          >
            <i className="fa-solid fa-share-nodes mr-2"></i>
            Share
          </button>

          {dropdownVisible && (
            <div className="absolute bottom-12 bg-white border border-gray-200 rounded shadow min-w-40">
              <div className="flex flex-col space-y-4 p-2">
                <FacebookShareButton
                  url={window.location.href}
                  quote={finalResponse.replace(/<\/?[^>]+(>|$)/g, "")}
                  className="flex items-center gap-2"
                >
                  <FacebookIcon size={20} round={false} /> <span>Facebook</span>
                </FacebookShareButton>
                <LinkedinShareButton
                  url={window.location.href}
                  summary={finalResponse.replace(/<\/?[^>]+(>|$)/g, "")}
                  className="flex items-center gap-2"
                >
                  <LinkedinIcon size={20} round={false} /> <span>LinkedIn</span>
                </LinkedinShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  title={finalResponse.replace(/<\/?[^>]+(>|$)/g, "")}
                  className="flex items-center gap-2"
                >
                  <TwitterIcon size={20} round={false} /> <span>Twitter</span>
                </TwitterShareButton>
                <WhatsappShareButton
                  url={window.location.href}
                  title={finalResponse.replace(/<\/?[^>]+(>|$)/g, "")}
                  className="flex items-center gap-2"
                >
                  <WhatsappIcon size={20} round={false} /> <span>Whatsapp</span>
                </WhatsappShareButton>
                <EmailShareButton
                  subject="Learning Activity"
                  body={finalResponse.replace(/<\/?[^>]+(>|$)/g, "")}
                  url=""
                  className="flex items-center gap-2"
                >
                  <EmailIcon size={20} round={false} /> <span>Email</span>
                </EmailShareButton>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const LearningActivity = () => {
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const [combinePrompt, setCombinePrompt] = useState("");
  const [finalResponse, setFinalResponse] = useState("");
  async function chatgpt(prompt) {
    try {
      const response = await fetch(
        "https://dasboard-backend-production.up.railway.app/chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            systemPrompt: `You will act as learning activity generator.
          
            Bold the heading sections using <h4> and its content in <p> tag. Use <p> for paragraphs. Directly start from <h2> heading title/topic. Introductory sentence is not required.
  
            Note everything should be in detail. Explanation should be present for all.`,
            userMessage: prompt,
            module: "learningActivity",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Error in response");
      }
      const result = await response.json();
      console.log(result);
      return result.message;
    } catch (e) {
      toast.error("Error generating content");
      console.error("Error:", e);
    }
  }

  async function generateAndDownloadWord() {
    try {
      const header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
      const footer = "</body></html>";

      const sourceHTML = header + finalResponse + footer;

      const source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(sourceHTML);
      const fileDownload = document.createElement("a");
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = "document.doc";
      fileDownload.click();
      document.body.removeChild(fileDownload);
    } catch (e) {
      toast.error("Error downloading word file");
      console.error("Error generating or downloading Word document:", e);
    }
  }

  async function generateAndDownloadPDF() {
    try {
      const response = await fetch(
        "https://dasboard-backend-production.up.railway.app/generate-pdf",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ htmlContent: finalResponse }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "document.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      toast.error("Error downloading Pdf file");
      console.error("Error generating or downloading Pdf document:", e);
    }
  }

  const [showTooltip, setShowTooltip] = useState(false);
  const copyToClipboard = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    const textToCopy = tempDiv.textContent || tempDiv.innerText || "";

    navigator.clipboard.writeText(textToCopy).then(() => {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    });
  };

  const Form = () => {
    // Field11 start
    const [discipline, setDiscipline] = useState("");
    const disciplinePrompt = `Discipline: ${discipline}`;
    // Field11 end

    // Field12 start
    const [areaOfStudy, setAreaOfStudy] = useState("");
    const areaOfStudyPrompt = `Area of study: ${areaOfStudy}`;
    // Field12 end

    // Field1 start
    const [technology, setTechnology] = useState("");
    const [otherField1, setOtherField1] = useState("");
    const technologyPrompt = `${
      technology == "Other"
        ? `Specific technology is: ${otherField1}`
        : technology == "Technology-enabled"
        ? "Technology-enabled: recommend an appropriate technology that complements the learning activity and a provide a detailed set of instructions on how this technology will be integrated into the activity."
        : "No use of technology: provide alternative methods to conduct the learning activity effectively without the use of technology"
    }`;
    // Field1 end

    // Field2 start
    const [degreeLevel, setDegreeLevel] = useState("");
    const degreeLevelPrompt = `The activity is to be designed specifically for ${degreeLevel} students.`;
    // Field2 end

    // Field3 start
    const [classSize, setClassSize] = useState("");
    const [otherField3, setOtherField3] = useState("");
    const classSizePrompt = `The activity must be suitable for a class size of ${
      classSize == "Other" ? `${otherField3}` : classSize
    } students.`;
    // Field3 end

    // Field4 start
    const [activitySize, setActivitySize] = useState("");
    const activitySizePrompt = `The activity must be suitable for an activity size of ${activitySize}.`;
    // Field4 end

    // Field5 start
    const [preparationTime, setPreparationTime] = useState("");
    const preparationTimePrompt = `The instructor’s preparation time for the activity should be ${preparationTime}.`;
    // Field5 end

    // Field6 start
    const [learningSpace, setLearningSpace] = useState("");
    const learningSpacePrompt = `The activity is intended for a ${learningSpace}.`;
    // Field6 end

    // Field7 start
    const [phaseOfSession, setPhaseOfSession] = useState("");
    const phaseOfSessionPrompt = `The activity will take place during the ${phaseOfSession}.`;
    // Field7 end

    // Field8 start
    const [activityDuration, setActivityDuration] = useState("");
    const activityDurationPrompt = `The activity duration should be ${activityDuration}.`;
    // Field8 end

    //Field9 start
    const [pedagogicalLearningModel, setPedagogicalLearningModel] = useState(
      []
    );
    const [showOtherField9, setShowOtherField9] = useState(false);
    const [otherField9, setOtherField9] = useState("");
    const handleToggleField9 = () => {
      setOtherField9("");
      setShowOtherField9(!showOtherField9);
    };
    const pedagogicalLearningModelPrompt = `Apply ${pedagogicalLearningModel}${
      otherField9 ? `,${otherField9}` : ""
    } in the activity.`;
    //Field9 end

    //Field10 start
    const [learningObjective, setLearningObjective] = useState([]);
    const [showOtherField10, setShowOtherField10] = useState(false);
    const [otherField10, setOtherField10] = useState("");
    const handleToggleField10 = () => {
      setOtherField10("");
      setShowOtherField10(!showOtherField10);
    };
    const learningObjectivePrompt = `The activity should aim to achieve the learning objective of ${learningObjective}${
      otherField10 ? `,${otherField10}` : ""
    }.`;
    //Field10 end

    const [errors, setErrors] = useState({
      discipline: false,
      areaOfStudy: false,
      technology: false,
      degreeLevel: false,
      classSize: false,
      activitySize: false,
      preparationTime: false,
      learningSpace: false,
      phaseOfSession: false,
      activityDuration: false,
      pedagogicalLearningModel: false,
      learningObjective: false,
    });

    const createPrompt = async () => {
      let formIsValid = true;
      const requiredFields = {
        discipline,
        areaOfStudy,
        technology,
        degreeLevel,
        classSize,
        activitySize,
        preparationTime,
        learningSpace,
        phaseOfSession,
        activityDuration,
        pedagogicalLearningModel,
        learningObjective,
      };
      const newErrors = { ...errors };

      Object.keys(requiredFields).forEach((field) => {
        if (!requiredFields[field]) {
          formIsValid = false;
          newErrors[field] = true;
        } else {
          newErrors[field] = false;
        }
      });

      setErrors(newErrors);

      if (technology === "Other" && otherField1 === "") {
        formIsValid = false;
        newErrors.technology = true;
      }

      if (classSize === "Other" && otherField3 === "") {
        formIsValid = false;
        newErrors.classSize = true;
      }

      if (
        pedagogicalLearningModel.length === 0 &&
        (!showOtherField9 || (showOtherField9 && otherField9 === ""))
      ) {
        formIsValid = false;
        newErrors.pedagogicalLearningModel = true;
      }

      if (
        learningObjective.length === 0 &&
        (!showOtherField10 || (showOtherField10 && otherField10 === ""))
      ) {
        formIsValid = false;
        newErrors.learningObjective = true;
      }

      if (formIsValid) {
        try {
          setLoading1(true);
          setTimeout(() => {
            setCombinePrompt(`Generate an elaborate and detailed learning activity plan that adheres to the following criteria:

${disciplinePrompt}

${areaOfStudyPrompt}

${technologyPrompt}

${degreeLevelPrompt}

${classSizePrompt}
            
${activitySizePrompt}
            
${preparationTimePrompt}
            
${learningSpacePrompt}
            
${phaseOfSessionPrompt}
            
${activityDurationPrompt}
            
${pedagogicalLearningModelPrompt}
            
${learningObjectivePrompt}
            
Provide the following in your learning activity plan:
            
Title and Description: Assign a descriptive title and provide a succinct overview of the activity, including its purpose and what it aims to achieve. Provide 2-3 clear and measurable learning objectives.
    
Step-by-Step Guide: provide a comprehensive, step-by-step guide that outlines a clear, actionable and concise process for executing the activity. 
            
${
  technology == "No use of technology"
    ? `Materials and Resources: Identify the key content, materials and resources that will be used to support the learning activity. Explain how these elements align with the defined learning outcomes. 
    
Engagement Strategies: provide the teaching methods, techniques, and activities to facilitate learner engagement and the achievement of the learning outcomes.`
    : `Materials and Resources: Identify the key content, materials and resources that will be used to support the learning activity. Explain how these elements align with the defined learning outcomes. 
    
Technology Setup and Use: Give precise instructions and guidelines for the preparation and utilization of the selected technology, ensuring the instructions and guidelines are easy to follow and apply.
    
Engagement Strategies: provide the teaching methods, techniques, and activities to facilitate learner engagement and the achievement of the learning outcomes.`
}

Assessment Methods: Describe the assessment methods that will be used to evaluate if the learning outcomes have been met, both during and at the end of the activity. Include formative and summative assessment strategies.`);

            setLoading1(false);
          }, 3000);
        } catch (error) {
          setLoading1(false);
          console.error(error);
        }
      }
    };

    function resetForm() {
      setDiscipline("");
      setAreaOfStudy("");
      setTechnology("");
      setOtherField1("");
      setDegreeLevel("");
      setClassSize("");
      setOtherField3("");
      setActivitySize("");
      setPreparationTime("");
      setLearningSpace("");
      setPhaseOfSession("");
      setActivityDuration("");
      setPedagogicalLearningModel([]);
      setOtherField9("");
      setLearningObjective([]);
      setOtherField10("");
      setCombinePrompt("");
      setFinalResponse("");
    }

    return (
      <div className="w-full lg:w-[50%] space-y-4">
        <div className="flex gap-4 items-center justify-between">
          <h2>Filters</h2>{" "}
          <button className="underline text-blue-500" onClick={resetForm}>
            Reset All
          </button>
        </div>

        {/* Field 11 and Field 12 */}
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full">
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="discipline"
            >
              DISCIPLINE{" "}
              {errors.discipline && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <input
              type="text"
              id="discipline"
              className={`text-sm border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0`}
              value={discipline}
              onChange={(e) => {
                setDiscipline(e.target.value);
              }}
              placeholder="(e.g., Mathematics)"
            />
          </div>
          <div className="w-full">
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="areaOfStudy"
            >
              AREA OF STUDY{" "}
              {errors.areaOfStudy && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <input
              type="text"
              id="areaOfStudy"
              className={`text-sm border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0`}
              value={areaOfStudy}
              onChange={(e) => {
                setAreaOfStudy(e.target.value);
              }}
              placeholder="(e.g., Algebra, Statistics, Geometry)"
            />
          </div>
        </div>

        {/* Field1 and Field2*/}
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full">
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="technology"
            >
              USE OF TECHNOLOGY{" "}
              {errors.technology && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="technology"
              id="technology"
              value={technology}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setTechnology(e.target.value);
                setErrors({ ...errors, technology: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {["Technology-enabled", "No use of technology", "Other"].map(
                (option) => (
                  <option value={option}>{option}</option>
                )
              )}
            </select>
            {technology === "Other" && (
              <input
                type="text"
                className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                  errors.technology ? "border-red-500" : "border-gray-300"
                }`}
                placeholder="Please specify"
                value={otherField1}
                onChange={(e) => {
                  setOtherField1(e.target.value);
                  setErrors({ ...errors, technology: false });
                }}
              />
            )}
          </div>
          <div className="w-full">
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="degreeLevel"
            >
              DEGREE LEVEL{" "}
              {errors.degreeLevel && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="degreeLevel"
              id="degreeLevel"
              value={degreeLevel}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setDegreeLevel(e.target.value);
                setErrors({ ...errors, degreeLevel: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {[
                "Associate",
                "Undergraduate",
                "Graduate",
                "Doctoral",
                "Technical/Vocational",
              ].map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
          </div>
        </div>
        {/* Field3 and Field4  */}

        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full">
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="classSize"
            >
              CLASS SIZE{" "}
              {errors.classSize && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="classSize"
              id="classSize"
              value={classSize}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setClassSize(e.target.value);
                setErrors({ ...errors, classSize: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {[
                "100+ students",
                "50+ students",
                "20 - 50 students",
                "Other",
              ].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {classSize === "Other" && (
              <input
                type="text"
                className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                  errors.classSize ? "border-red-500" : "border-gray-300"
                }`}
                placeholder="Please specify"
                value={otherField3}
                onChange={(e) => {
                  setOtherField3(e.target.value);
                  setErrors({ ...errors, classSize: false });
                }}
              />
            )}
          </div>
          <div className="w-full">
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="activitySize"
            >
              ACTIVITY SIZE{" "}
              {errors.activitySize && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="activitySize"
              id="activitySize"
              value={activitySize}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setActivitySize(e.target.value);
                setErrors({ ...errors, activitySize: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {[
                "Large group > 10",
                "Small group < 10",
                "Pairs",
                "Individual",
              ].map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Field5 and Field6  */}

        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full">
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="preparationTime"
            >
              PREPARATION TIME{" "}
              {errors.preparationTime && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="preparationTime"
              id="preparationTime"
              value={preparationTime}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setPreparationTime(e.target.value);
                setErrors({ ...errors, preparationTime: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {[
                "Less than 10 minutes",
                "Between 10 and 25 minutes",
                "More than 25 minutes",
              ].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full">
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="learningSpace"
            >
              LEARNING SPACE{" "}
              {errors.learningSpace && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="learningSpace"
              id="learningSpace"
              value={learningSpace}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setLearningSpace(e.target.value);
                setErrors({ ...errors, learningSpace: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {[
                "Lecture theatre",
                "Classroom",
                "Laboratory/studio",
                "Computer lab",
                "Online/virtual",
              ].map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Field7 and Field8  */}

        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full">
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="phaseOfSession"
            >
              PHASE OF SESSION{" "}
              {errors.phaseOfSession && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="phaseOfSession"
              id="phaseOfSession"
              value={phaseOfSession}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setPhaseOfSession(e.target.value);
                setErrors({ ...errors, phaseOfSession: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {["Pre-class", "In-class", "Post-class (out-of-class)"].map(
                (option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="w-full">
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="activityDuration"
            >
              ACTIVITY DURATION{" "}
              {errors.activityDuration && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="activityDuration"
              id="activityDuration"
              value={activityDuration}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setActivityDuration(e.target.value);
                setErrors({ ...errors, activityDuration: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {[
                "Less than 10 minutes",
                "Between 10 and 25 minutes",
                "More than 25 minutes",
              ].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Field9 */}
        <div>
          <label
            className="text-gray-600 font-semibold block mb-2"
            htmlFor="classSize"
          >
            PEDAGOGICAL LEARNING MODEL{" "}
            {errors.pedagogicalLearningModel && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="grid grid-cols-2 gap-3">
            {[
              "Authentic learning",
              "Experiential learning",
              "Problem-based learning",
              "Case-base learning",
              "Inquiry-based learning",
              "Self-directed learning",
              "Collaborative learning",
              "Peer-assisted learning",
            ].map((option) => (
              <div>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    className="form-checkbox text-blue-500 focus:ring-0"
                    checked={pedagogicalLearningModel.includes(option)}
                    onChange={() => {
                      setPedagogicalLearningModel((prev) =>
                        prev.includes(option)
                          ? prev.filter((item) => item !== option)
                          : [...prev, option]
                      );
                      setErrors({ ...errors, pedagogicalLearningModel: false });
                    }}
                  />
                  <span>{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <label className="flex items-center space-x-2">
              <div
                onClick={handleToggleField9}
                className="p-0.5 bg-gray-200 rounded-full cursor-pointer text-black"
              >
                {showOtherField9 ? <Minus size={14} /> : <Plus size={14} />}
              </div>
              <span>Other</span>
            </label>
            {showOtherField9 && (
              <input
                type="text"
                className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                  errors.pedagogicalLearningModel
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                placeholder="Please specify"
                value={otherField9}
                onChange={(e) => {
                  setOtherField9(e.target.value);
                  setErrors({ ...errors, pedagogicalLearningModel: false });
                }}
              />
            )}
          </div>
        </div>

        {/* Field10 */}
        <div>
          <label
            className="text-gray-600 font-semibold block mb-2"
            htmlFor="classSize"
          >
            LEARNING OBJECTIVE{" "}
            {errors.learningObjective && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="grid grid-cols-2 gap-3">
            {[
              "Remember",
              "Understand",
              "Apply",
              "Analyze",
              "Evaluate",
              "Create",
            ].map((option) => (
              <div>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    className="form-checkbox text-blue-500 focus:ring-0"
                    checked={learningObjective.includes(option)}
                    onChange={() => {
                      setLearningObjective((prev) =>
                        prev.includes(option)
                          ? prev.filter((item) => item !== option)
                          : [...prev, option]
                      );
                      setErrors({ ...errors, learningObjective: false });
                    }}
                  />
                  <span>{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <label className="flex items-center space-x-2">
              <div
                onClick={handleToggleField10}
                className="p-0.5 bg-gray-200 rounded-full cursor-pointer text-black"
              >
                {showOtherField10 ? <Minus size={14} /> : <Plus size={14} />}
              </div>
              <span>Other</span>
            </label>
            {showOtherField10 && (
              <input
                type="text"
                className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                  errors.learningObjective
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                placeholder="Please specify"
                value={otherField10}
                onChange={(e) => {
                  setOtherField10(e.target.value);
                  setErrors({ ...errors, learningObjective: false });
                }}
              />
            )}
          </div>
        </div>

        <br></br>
        <button
          className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          onClick={createPrompt}
          disabled={loading1 || loading2 || loading3}
        >
          {loading1 ? (
            <div className="flex items-center justify-center gap-2">
              <span>Creating... </span>
              <i class="fa-solid fa-spinner animate-spin"></i>
            </div>
          ) : (
            "Create Prompt"
          )}
        </button>
      </div>
    );
  };

  async function generateContent() {
    try {
      if (combinePrompt) {
        setLoading2(true);
        const response = await chatgpt(combinePrompt);
        setFinalResponse(response);
        setLoading2(false);
      }
    } catch (e) {
      toast.error("Error generating prompt");
      setLoading2(false);
      console.error("Error generating prompt:", e);
    }
  }

  async function regenerateContent() {
    try {
      if (combinePrompt) {
        setLoading3(true);
        const response = await chatgpt(combinePrompt);
        setFinalResponse(response);
        setLoading3(false);
      }
    } catch (e) {
      toast.error("Error regenerating prompt");
      setLoading3(false);
      console.error("Error regenerating prompt:", e);
    }
  }

  return (
    <div className="w-full mx-auto p-0 sm:p-6">
      <ToastContainer />
      <div className="flex flex-col lg:flex-row gap-8">
        <Form />
        <div className="w-full lg:w-[50%] flex flex-col mt-4">
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <img src={iconImage} className="w-auto max-w-10 max-h-10"></img>
              <div className="flex flex-col">
                <p className="m-0 text-gray-700 font-semibold">
                  Automatic Prompt Generator
                </p>
                <p className="m-0 text-xs text-gray-400 font-semibold">
                  Generate Learning Activity
                </p>
              </div>
            </div>

            <textarea
              className="enhanced-textarea border border-gray-300 py-2 px-3 w-full text-sm focus:ring-0"
              rows={8}
              value={combinePrompt}
              onChange={(e) => {
                setCombinePrompt(e.target.value);
              }}
            ></textarea>
            <button
              className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              onClick={generateContent}
              disabled={loading1 || loading2 || loading3}
            >
              {loading2 ? (
                <div className="flex items-center justify-center gap-2">
                  <span>Generating... </span>
                  <i class="fa-solid fa-spinner animate-spin"></i>
                </div>
              ) : (
                "Generate Learning Activity"
              )}
            </button>
          </div>

          <div className="mt-8 p-2 border-gray-200 border-solid border w-full bg-gray-100 rounded-lg overflow-y-auto">
            <div
              id="msg"
              className="p-2 enhanced-textarea max-h-[600px] overflow-y-auto"
            >
              <div className={`mt-4 mb-4 text-left relative`}>
                <span className="inline-block p-4 w-full rounded-md bg-white">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: finalResponse,
                    }}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="mt-4 flex flex-wrap justify-start gap-4">
            <div className="relative">
              <div
                className="flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 cursor-pointer hover:bg-gray-200"
                onClick={() => copyToClipboard(finalResponse)}
              >
                <i className="fas fa-copy mr-2"></i>
                {"Copy"}
              </div>
              {showTooltip && (
                <div className="absolute -top-8 right-0 text-xs rounded bg-black text-white py-1 px-2">
                  Copied!
                </div>
              )}
            </div>
            <div
              className={`flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 ${
                finalResponse
                  ? "cursor-pointer hover:bg-gray-200"
                  : "cursor-not-allowed"
              }`}
              onClick={
                finalResponse
                  ? () => generateAndDownloadPDF()
                  : (e) => e.preventDefault()
              }
              disabled={!finalResponse}
            >
              <i class="fas fa-file-pdf mr-2"></i>
              {"Download Pdf"}
            </div>
            <div
              className={`flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 ${
                finalResponse
                  ? "cursor-pointer hover:bg-gray-200"
                  : "cursor-not-allowed"
              }`}
              onClick={
                finalResponse
                  ? () => generateAndDownloadWord()
                  : (e) => e.preventDefault()
              }
              disabled={!finalResponse}
            >
              <i class="fas fa-file-word mr-2"></i>
              {"Download Word"}
            </div>
            <button
              className={`flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 ${
                finalResponse
                  ? "cursor-pointer hover:bg-gray-200"
                  : "cursor-not-allowed"
              }`}
              onClick={regenerateContent}
              disabled={loading1 || loading2 || loading3}
            >
              {loading3 ? (
                <div className="flex items-center justify-center gap-2">
                  <span>Regenerating... </span>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-2">
                  <RefreshCcw size={20} />
                  <span>Regenerate</span>
                </div>
              )}
            </button>
            <ShareButton finalResponse={finalResponse} />
          </div>
        </div>
      </div>
    </div>
  );
};
